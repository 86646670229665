main {
  align-content: center;
  box-sizing: border-box;
  display: grid;
  font-family: "SF Pro Text", "SF Pro Icons", "Helvetica Neue", "Helvetica",
    "Arial", sans-serif;
  hyphens: auto;
  line-height: 1.65;
  margin: 0 auto;
  max-width: 680px;
  min-height: 100vh;
  padding: 72px 0;
  text-align: center;
}
h2 {
  margin-top: 1.5em;
}
p {
  font-size: 16px;
}
a {
  cursor: pointer;
  text-decoration: none;
  transition: all 0.2s ease;
  color: inherit;
}
a:hover {
  opacity: 0.7;
  color: inherit;
  /* text-decoration: underline; */
}
a:visited {
  color: inherit;
}

code,
pre {
  color: #d400ff;
  font-family: Menlo, Monaco, Lucida Console, Liberation Mono, DejaVu Sans Mono,
    Bitstream Vera Sans Mono, Courier New, monospace, serif;
  font-size: 0.92em;
}
code:before,
code:after {
  content: "\`";
}

#download-btn {
  transition: all 300ms ease;
}

#download-btn:hover {
  transform: scale(1.1);
}

/* media queries */

@media only screen and (min-width: 768px) {
  #slogan-text {
    font-size: 110px;
  }
  #hero-text-flex {
    text-align: right;
  }
}
